import { VueSelect, VueSelectOption } from '@ui-common/vue-select';

export default {
	components: {
		VueSelect,
		VueSelectOption
	},
	data() {
		return {
			selectedServices: {}
		};
	}
};
