export default {
	name: 'readMoreBlock',
	data: () => ({
		isVisible: false
	}),
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	}
};
