import VueTab from '@ui-common/vue-tab';
import getQueryParam from 'get-query-param';
import { isMobile } from '@ui-common/sf-js-utils';
export default {
	mixins: [isMobile],
	props: {
		id: {
			type: String,
			required: true
		}
	},
	components: {
		VueTab
	},
	methods: {
		pushDatalayer(event) {
			if (this.isMobile) {
				this.$gtm.trackEvent({
					event: 'dropDownSelect',
					formElementID: 'Bankszámlanyitás',
					formID: 'Kategóriát választ',
					inputValue: document.getElementById(`sf-tab-head-rco-${event}`).lastChild.innerText
				});
			}
		}
	},
	computed: {
		urlTabId() {
			return parseInt(getQueryParam(`${this.id}-tab`, window.location.href), 10) - 1 || 0;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs['mtabr-root'].scrollIntoView();
		});
	}
};
