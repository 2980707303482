import axios from 'axios';
const personalLoanBackendPrefix = '/apps/personalloan';
export default {
	calculateAmount(data) {
		return axios.post(personalLoanBackendPrefix + '/api/public/calculator2021/amount', data);
	},
	calculateMonthlyFee(data) {
		return axios.post(personalLoanBackendPrefix + '/api/public/calculator2021/monthlyfee', data);
	},
	getConfig() {
		return axios.get(personalLoanBackendPrefix + '/api/public/config2021');
	},
	getSelectValues() {
		return axios.get(personalLoanBackendPrefix + '/api/public/term2021');
	},
	getInterestList() {
		return axios.get(personalLoanBackendPrefix + '/api/public/interest2021');
	},
	sendTa(data) {
		return axios.post(personalLoanBackendPrefix + '/api/ta', data);
	}
};
