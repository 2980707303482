import './layout.scss';
import Vue from 'vue';
import VueTableOfContent from '@ui-common/vue-table-of-content';
import macc from '../../modules/MACC/macc';
import mhap from '../../modules/MHAP/mhap.vue';
import mdtb from '../../modules/MDTB/mdtb';
import meda from '../../modules/MEDA/meda';
import meok from '../../modules/MEOK/meok';
import mevp from '../../modules/MEVP/mevp';
import mint from '../../modules/MINT/mint.vue';
import mint2021 from '../../modules/MINT2021/mint2021.vue';
import mjbl from '../../modules/MJBL/mjbl.vue';
import mkkm from '../../modules/MKKM/mkkm';
import mksz from '../../modules/MKSZ/mksz';
import mnib from '../../modules/MNIB/mnib';
import moav from '../../modules/MOAV/moav';
import mrbt from '../../modules/MRBT/mrbt';
import mstb from '../../modules/MSTB/mstb';
import mtab from '../../modules/MTAB/mtab';
import mtabr from '../../modules/MTABR/mtabr';
import mtbl from '../../modules/MTBL/mtbl';
import mubt from '../../modules/MUBT/mubt';
import mslc from '../../modules/MSLC/mslc';
import masd from '../../modules/MASD/masd.vue';
import readMoreBlock from '../../common/components/read-more-block/read-more-block';
import announcementsSelect from '../../common/components/announcements-select/announcements-select.js';
import BranchAtmWidgetWrapper from '../../applications/branch-atm-widget-wrapper/branch-atm-widget-wrapper.vue';
import layoutBase from '../layout-base';

Vue.component('macc', macc);
Vue.component('mslc', mslc);
Vue.component('mdtb', mdtb);
Vue.component('meok', meok);
Vue.component('mevp', mevp);
Vue.component('mint', mint);
Vue.component('mint2021', mint2021);
Vue.component('mjbl', mjbl);
Vue.component('mrbt', mrbt);
Vue.component('mstb', mstb);
Vue.component('mtab', mtab);
Vue.component('mtabr', mtabr);
Vue.component('mtbl', mtbl);
Vue.component('mubt', mubt);
Vue.component('meda', meda);
Vue.component('mkkm', mkkm);
Vue.component('mksz', mksz);
Vue.component('mnib', mnib);
Vue.component('moav', moav);
Vue.component('mhap', mhap);
Vue.component('masd', masd);
Vue.component('read-more-block', readMoreBlock);
Vue.component('announcements-select', announcementsSelect);
Vue.component('vue-table-of-content', VueTableOfContent);
Vue.component('branch-atm-widget-wrapper', BranchAtmWidgetWrapper);

/* eslint-disable no-new*/
new Vue({
	extends: layoutBase,
	el: '[data-layout-id=AV9EL-app]'
});
/* eslint-enable*/
