import personalLoanCalculatorApi from '../../api/personal-loan-calculator-api';
const INTEREST_TYPE_DICTIONARY = {
	'normal-interest': 'NORMAL',
	'interest-rate': 'PROMOTIONAL',
	'interest-rate-online-discount': 'ONLINE'
};

export default {
	data: () => ({
		interestListResponse: [],
		technicalError: false,
		currentColumn: 0,
		columns: 1
	}),
	props: {
		interestType: {
			type: String,
			required: true
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		async getInterestList() {
			try {
				let response = await personalLoanCalculatorApi.getInterestList(INTEREST_TYPE_DICTIONARY[this.interestType]);
				this.interestListResponse = response.data;
				this.$store.dispatch('personalLoanCalculator/setInterestList', this.interestListResponse);
			} catch (err) {
				console.log(err);
				this.technicalError = true;
			}
		}
	},
	created() {
		this.getInterestList();
	}
};
