import VueFocus from 'vue-focus';
export default {
	mixins: [VueFocus.mixin],
	data() {
		return {
			activeTab: null
		};
	},
	props: {
		openedTab: {
			required: false
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		changeTab(tabId) {
			this.activeTab = 'tab' + tabId;
		}
	},
	mounted() {
		this.activeTab = 'tab' + this.openedTab;
	}
};
