import personalLoanCalculatorApi from '../../api/personal-loan-calculator-2021-api';

export default {
	data: () => ({
		interestListResponse: [],
		technicalError: false,
		currentColumn: 0,
		columns: 2
	}),
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		async getInterestList() {
			try {
				let response = await personalLoanCalculatorApi.getInterestList();
				this.interestListResponse = response.data;
			} catch (err) {
				console.log(err);
				this.technicalError = true;
			}
		}
	},
	created() {
		this.getInterestList();
	}
};
