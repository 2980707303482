import axios from 'axios';
const personalLoanBackendPrefix = '/apps/personalloan';
export default {
	calculateAmount(data) {
		return axios.post(personalLoanBackendPrefix + '/api/public/calculator/amount', data);
	},
	calculateMonthlyFee(data) {
		return axios.post(personalLoanBackendPrefix + '/api/public/calculator/monthlyfee', data);
	},
	getConfig() {
		return axios.get(personalLoanBackendPrefix + '/api/public/config');
	},
	getSelectValues() {
		return axios.get(personalLoanBackendPrefix + '/api/public/term');
	},
	getInterestList(type) {
		return axios.get(personalLoanBackendPrefix + `/api/public/interest/${type}`);
	},
	sendTa(data) {
		return axios.post(personalLoanBackendPrefix + '/api/ta', data);
	}
};
