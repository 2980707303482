import Vue from 'vue';
import VueTouch from 'vue-touch';
import VueFocus from 'vue-focus';
import eventBus from '../../common/js/event-bus/event-bus';
Vue.use(VueTouch);
export default {
	mixins: [VueFocus.mixin],
	data() {
		return {
			currentColumn: 0,
			cardWidth: null,
			deviceWidth: null,
			activeTab: null,
			focusedTab: null,
			stickyTop: null,
			visible: false,
			isSticky: false
		};
	},
	props: {
		modalId: {
			required: false
		},
		openedTab: {
			required: false
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		isTablet() {
			return this.$resize && this.$mq.above(768) && this.$mq.below(959);
		},
		isDesktop() {
			return this.$resize && this.$mq.above(960) && this.$mq.below(1279);
		},
		isLargeDesktop() {
			return this.$resize && this.$mq.above(1280);
		},
		keyHandler(e) {
			if (this.visible) {
				e.preventDefault();
				if (e.which == 27) {
					this.closePopup();
				}
			}
		}
	},
	methods: {
		lastTabHandler(e) {
			if (!e.shiftKey) {
				e.preventDefault();
				this.$parent.$refs.firstTabbable.focus();
			}
		},
		changeTab(tabId) {
			this.activeTab = 'tab' + tabId;
			this.focusedTab = tabId;
			eventBus.$emit('mubt-tab-change', { activeTab: this.activeTab, focusedTab: this.focusedTab });
		},
		sendStickyEvent() {
			let scrollWidth = 0;
			let sticky = false;
			if (this.$refs.results && this.$parent.$refs.dialog && this.$parent.$refs.document && this.$parent.$refs.body) {
				sticky =
					this.$parent.$refs.dialog.scrollTop >=
					this.$refs.results.offsetTop + this.$parent.$refs.document.offsetTop + this.$parent.$refs.body.offsetTop;
				scrollWidth = this.$parent.$refs.dialog.offsetWidth - this.$parent.$refs.dialog.clientWidth;
			}
			this.isSticky = sticky;
			eventBus.$emit('mubt-sticky', { sticky, scrollWidth });
		}
	},
	mounted() {
		this.activeTab = 'tab' + this.openedTab;
		this.$nextTick(() => {
			eventBus.$emit('mubt-tab-change', { activeTab: this.activeTab });
			if (this.$parent.$refs.dialog) {
				this.$parent.$refs.dialog.addEventListener('scroll', this.sendStickyEvent);
			}
			window.addEventListener('resize', this.sendStickyEvent);
		});
		eventBus.$on('mubt-head-tab-change', (activeTab) => {
			this.activeTab = activeTab;
		});
		eventBus.$on('modal-opened', () => {
			document.body.classList.add('mubt-modal-open');
		});
		eventBus.$on('modal-closed', () => {
			document.body.classList.remove('mubt-modal-open');
		});
	}
};
