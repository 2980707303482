import { VueSelect, VueSelectOption } from '@ui-common/vue-select';

export default {
	data: () => ({
		year: ''
	}),
	components: {
		VueSelect,
		VueSelectOption
	},
	created() {
		this.year = parseInt(window.location.href.split('/').pop());
	},
	watch: {
		year(val, oldVal) {
			if (val && oldVal && val != oldVal) {
				const newurl = window.location.href.split('/').slice(0, -1).join('/') + '/' + val;
				window.location.href = newurl;
			}
		}
	}
};
